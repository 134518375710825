// This is the div within which the signature canvas is fitted
.jsign-container {
  border: 2px dotted $black;

  canvas {
    &.disabled {
      background-color: var(--bs-secondary-bg) !important;
      margin-bottom: -0.35em !important;

      @include media-breakpoint-up(xl) {
        margin-bottom: -0.4em !important;
      }
    }
  }
}
