@use "sass:map";

body {
  // Use vh as a fallback for older browsers that don't support dynamic viewports
  min-height: 100vh;
  min-height: 100dvh;
}

main {
  flex: 1 auto;
}

.text-xs {
  font-size: 0.975rem;
}

.text-sm {
  font-size: 0.975rem;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.text-gray-700 {
  color: $gray-700;
}

// stylelint-disable scss/selector-nest-combinators, selector-class-pattern
svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  box-sizing: content-box;
  overflow: visible;
}
// stylelint-enable scss/selector-nest-combinators, selector-class-pattern

@include media-breakpoint-up(lg) {
  .dropdown {
    .dropdown-menu {
      display: block;
      opacity: 0;
      overflow: hidden;
      transform: translateX(-25%) scaleY(0);
      transform-origin: top;
      transition: all 0.32s;

      &.show {
        opacity: 1;
        transform: translateX(-25%) scaleY(1);
      }
    }
  }
}

.carrotpath-toast {
  background-color: $white;
  bottom: 120px;
  left: 40px;
  position: fixed;
  z-index: 3;

  h5 {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: middle;
  }

  // TODO: Is this still used?
  i {
    color: $green;
    margin-right: 10px;
    margin-top: 5px;
  }
}

label {
  &::first-letter {
    text-transform: capitalize;
  }
}

.card-header {
  &.rounded-top {
    border-radius: 1rem 1rem 0 0 !important;
  }
}

// stylelint-disable color-no-hex
.btn-medium {
  background-color: #d7dbd8;
  border-color: #444252;
  box-shadow: 5px 5px $gray-600;
  color: #030303;

  &:hover {
    background-color: #f3f3f3;
    border-color: #444252;
    color: #030303;
  }
}
// stylelint-enable color-no-hex

.messages-container {
  .alert {
    --#{$prefix}alert-border-radius: 0;
  }
}

.fake-form-control {
  background-color: var(--#{$prefix}secondary-bg);
  min-height: 38px;
  opacity: 1;
}

.disclaimer {
  color: var(--#{$prefix}tertiary-color);
  font-size: 0.875rem;
  text-align: center;
}

.page-sm {
  .container {
    max-width: map.get($container-max-widths, "sm");
  }
}

.page-lg {
  .container {
    max-width: map.get($container-max-widths, "lg");
  }
}

.htmx-indicator {
  opacity: 0;
}
